/** @jsx jsx **/

import { Component } from "react";
import { jsx, css } from "@emotion/core";
import { json, csv, keys, extent, max, nest } from "d3";
import { select, selectAll, event as currentEvent } from "d3-selection";
import { format } from "d3-format";
import { mean } from "d3-array";
import { scaleOrdinal, scaleLinear, scaleBand } from "d3-scale";
import { axisBottom, axisLeft, axisTop } from "d3-axis";
import { line, area, curveBasis } from "d3-shape";
import d3Tip from "d3-tip";

import Monospace from "../components/Monospace";

import data from "../assets/data/percent_time_relationship.csv";

const d3 = {
  csv,
  select,
  selectAll,
  mean,
  scaleOrdinal,
  scaleLinear,
  json,
  keys,
  extent,
  max,
  axisBottom,
  axisLeft,
  line,
  area,
  curveBasis,
  format,
  nest,
  scaleBand,
  axisTop
};

const styles = css`
  display: flex;
  flex-direction: column;
`;

export default class Density extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: this.props.width,
      height: 320,
      data: null
    };
  }

  componentDidMount() {
    d3.csv(data)
      .then(csvData => {
        this.setState({ data: csvData });

        let finalData = this.formatData();

        this.initialize(finalData);
      })
      .catch(function(err) {
        throw err;
      });
  }

  formatData = () => {
    let filteredData = this.state.data.filter(
      d => d[this.props.demo] === this.props.currentDemo
    );

    return filteredData;
  };

  initialize = data => {
    var margin = { top: 40, right: 20, bottom: 40, left: 20 };

    var svg = d3
      .select("#" + this.props.variable + "-density-container")
      .append("svg")
      .attr("width", this.state.width)
      .attr("height", this.state.height)
      .append("g")
      .attr("id", this.props.variable + "-density")
      .attr("transform", "translate(" + margin.left + ", " + margin.top + ")");

    this.setState({
      width: this.state.width - margin.right - margin.left,
      height: this.state.height - margin.top - margin.bottom
    });

    this.update(data);
  };

  update = data => {
    var svg = d3.select("#" + this.props.variable + "-density");

    var tip = d3Tip()
      .attr("class", "d3-tip")
      .html(d => d.value)
      .style("pointer-events", "none");
    svg.call(tip);

    var xScale = d3
      .scaleLinear()
      .domain([0, 1])
      .range([0, this.state.width]);
    svg
      .append("g")
      .attr("transform", "translate(0," + this.state.height + ")")
      .call(d3.axisBottom(xScale));

    // add the y Axis
    var yScale = d3
      .scaleLinear()
      .range([this.state.height, 0])
      .domain([0, 2]);
    //svg.append("g").call(d3.axisLeft(yScale));

    var kde = kernelDensityEstimator(
      kernelEpanechnikov(0.14),
      xScale.ticks(40)
    );
    var density = kde(data.map(d => d.pct_relationship));

    var colorScale = {
      "Class of 2020": "#A9AEA5",
      "Class of 2021": "#F0C6CD",
      "Class of 2022": "#E0A69E",
      "Class of 2023": "#B37C8D"
    };

    svg
      .append("path")
      .attr("class", "mypath")
      .datum(density)
      .attr("fill", colorScale[this.props.currentDemo])
      .style("opacity", 0.6)
      .attr("stroke", colorScale[this.props.currentDemo])
      .attr("stroke-width", 1)
      .attr("stroke-linejoin", "round")
      .attr(
        "d",
        d3
          .area()
          .curve(d3.curveBasis)
          .x(function(d) {
            return xScale(d[0]);
          })
          .y0(this.state.height)
          .y1(function(d) {
            return yScale(d[1]);
          })
      );

    function kernelDensityEstimator(kernel, X) {
      return function(V) {
        return X.map(function(x) {
          return [
            x,
            d3.mean(V, function(v) {
              return kernel(x - v);
            })
          ];
        });
      };
    }
    function kernelEpanechnikov(k) {
      return function(v) {
        return Math.abs((v /= k)) <= 1 ? (0.75 * (1 - v * v)) / k : 0;
      };
    }
  };

  render() {
    return (
      <div css={styles}>
        <Monospace>{this.props.currentDemo}</Monospace>
        <div
          id={this.props.variable + "-density-container"}
          style={{ margin: "auto", flex: 1 }}
        ></div>
      </div>
    );
  }
}
