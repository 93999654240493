/** @jsx jsx */

import React, { Component } from "react";
import { jsx, css } from "@emotion/core";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ImageFadeIn from "react-image-fade-in";

const style = css`
  background-color: #ebdad0;
  padding: 1em;
  width: 160px;
  font-size: 0.8em;
  .title {
    font-weight: bold;
  }
  .selector {
    background-color: #fff;
    padding: 0.5em 0.8em;
    margin: 0.5em 0;
    cursor: pointer;
  }
`;

const demographics = [
  "Class Year",
  "Major",
  "Athlete",
  "Social Org",
  "Race",
  "Gender",
  "Religion",
  "School"
];

class DemographicSelect extends Component {
  render() {
    return (
      <div css={style}>
        <div className="title">Choose demographic tab:</div>
        {demographics.map(demo => (
          <div
            key={demo}
            className="selector"
            style={{ fontWeight: this.props.currentDemo === demo ? 600 : 400 }}
            onClick={e => this.props.handleChange(demo)}
          >
            {demo}
          </div>
        ))}
      </div>
    );
  }
}

export default DemographicSelect;
