import React from "react";

import Header from "./sections/Header";
import DTF from "./sections/DTF";
import ForeverAlone from "./sections/ForeverAlone";
import Footer from "./sections/Footer";

import LazyLoad from "react-lazy-load";
import "bootstrap/dist/css/bootstrap.min.css";

import { MixpanelConsumer } from "react-mixpanel";

function App() {
  return (
    <div className="pageContainer">
      <MixpanelConsumer>
        {mixpanel => <Header mixpanel={mixpanel} />}
      </MixpanelConsumer>
      <LazyLoad once>
        <MixpanelConsumer>
          {mixpanel => <DTF mixpanel={mixpanel} />}
        </MixpanelConsumer>
      </LazyLoad>
      <LazyLoad once>
        <MixpanelConsumer>
          {mixpanel => <ForeverAlone mixpanel={mixpanel} />}
        </MixpanelConsumer>
      </LazyLoad>
      <Footer />
    </div>
  );
}

export default App;
