/** @jsx jsx */

import React, { Component } from "react";
import { jsx, css } from "@emotion/core";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ImageFadeIn from "react-image-fade-in";

import RightFlowers from "../assets/graphics/flowers.svg";
import LeftFlowers from "../assets/graphics/flowers_two.svg";

const headerStyle = css`
  min-height: 100vh;
  .headerBackground {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    background: rgb(243, 240, 229);
    background: linear-gradient(
      180deg,
      rgba(243, 240, 229, 1) 0%,
      rgba(244, 241, 231, 1) 72%,
      rgba(255, 255, 255, 1) 100%
    );
    z-index: -1;
  }

  .headerText {
    text-align: center;
    padding-top: 10%;
    width: calc(100vw - (100vw - 100%));
  }

  .link {
    color: #212529;
  }

  .link:hover {
    color: #212529;
  }

  .headline {
    font-size: 5em;
    font-weight: 700;
    line-height: 1.3em;
  }

  .introText {
    width: 30%;
    margin: auto;
    margin-top: 2em;
  }

  @media only screen and (max-width: 1000px) {
    .introText {
      width: 60%;
    }
    .headline {
      font-size: 3.5em;
    }
  }
`;

class Header extends Component {
  componentDidMount() {
    this.props.mixpanel.track("Visited website (loaded header)");
  }
  render() {
    return (
      <div id="top" css={headerStyle}>
        <div className="headerBackground">
          <ImageFadeIn src={LeftFlowers} width="30%" />
          <ImageFadeIn src={RightFlowers} width="30%" />
        </div>
        <div className="headerText">
          <div className="monospace" style={{ fontSize: "2em" }}>
            <a
              className="link"
              href="https://datamatch.me/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Datamatch
            </a>{" "}
            x{" "}
            <a
              className="link"
              href="https://hodp.org/"
              rel="noopener noreferrer"
              target="_blank"
            >
              HODP
            </a>
          </div>
          <div className="monospace" style={{ fontSize: "1.4em" }}>
            present
          </div>
          <div className="headline">
            <div style={{ width: "40%", margin: "auto" }}>
              The State of Love
            </div>
          </div>
          <div className="introText">
            <p>
              As Valentine's Day draws ever-closer and months in isolation grow
              longer, our thoughts often turn to relationships. Are certain
              schools 'better' or 'worse' to be in a relationship in? What are
              our peers up to? Datamatch gathered responses from over 2,400
              students to judge the State of Love at colleges around the nation
              and show you how other students are navigating love,
              relationships, and more both pre-COVID and after.{" "}
            </p>
            <p>
              Our analysis is split into 5 sections, covering topics from
              'hook-up culture' to COVID to self-perception and self-worth. We
              plan on releasing sections periodically over the next month in the
              lead-up to Valentine's Day and the Datamatch launch - so bookmark
              this page! Our first section explores 'hook-ups', demographics,
              and entering and leaving relationships.{" "}
            </p>
            <p>
              This project is still in progress! Check back in soon and we might
              have added more content.{" "}
              {window.innerWidth < 800 && (
                <>
                  And we recommend viewing this website on a computer—we're
                  still working on mobile compatibility :)
                </>
              )}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
