/** @jsx jsx */

import React, { Component } from "react";
import { jsx, css } from "@emotion/core";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Paragraph from "../components/Paragraph";
import Title from "../components/Title";
import Subtitle from "../components/Subtitle";
import Monospace from "../components/Monospace";
import Description from "../components/Description";
import Quote from "../components/Quote";

import YNBarChart from "../viz/yn_bar_chart";
import Circlepacking from "../viz/circlepacking";
import Sankey from "../viz/sankey";
import Donut from "../viz/donut";
import Density from "../viz/density";
import Scatter from "../viz/scatter";

const DTFStyle = css``;

class DTF extends Component {
  componentDidMount() {
    this.props.mixpanel.track("Loaded DTF");
  }
  render() {
    return (
      <div css={DTFStyle} id="DTF">
        <Container>
          <Title>DTF or DTR?</Title>
          <Description>
            It can often feel like everyone is in a relationship except for you
            – especially when "Cuffing Season" rolls around and your friends
            pair up and head off to their coffee dates. But is everyone really
            cuffed but you?
          </Description>
          <Paragraph>
            Our survey sample includes 2,403 students from over 28 different
            schools, and was conducted in early April of 2020, just after most
            of us were sent back home. We first asked respondents about their
            current relationship status - the vast majority were not in a
            relationship at the time (approximately 80%), although most of those
            not in a relationship were looking for one. As a whole, our sample
            was split as follows:
          </Paragraph>
          <Donut variable="relationship_status" />
          <Paragraph>
            How do these compositions vary by school - and among your peers? We
            split up both those that reported having "hooked up" during college
            (which we defind as a sexual encounter without the expectation of a
            committed relationship), and those that reported having been in a
            relationship.
          </Paragraph>
          <Row>
            <Col lg={4}>
              <Subtitle>Who is hooking up with whom?</Subtitle>
              <Paragraph>
                We first looked at the percent of college students who reported
                having a "sexual encounter" without the expectation of a
                committed relationship.
              </Paragraph>
              <Monospace>
                Use the cross tabs to explore the average responses by
                demographics as well as social characteristics – e.g. within
                student athlete and social organization members. You can also
                filter by school (although we've excluded the ability to subset
                for schools with insufficient responses for privacy reasons)
              </Monospace>
            </Col>
            <Col lg={8}>
              <YNBarChart variable="sexual_encounter" />
            </Col>
          </Row>
          <Paragraph>
            Many expressed sentiments like "hookup culture is large among boys
            but girls definitely want more commitment", but the data does not
            bear this out - those that identified as women reported having
            hooked up at a higher rate than those that identified as men, and
            the average number of relationships for men and women were nearly
            identical - 0.697 and 0.7.
          </Paragraph>
          <Quote>
            "Generally Hookup Culture is large among boys but girls definitely
            want more commitment."
          </Quote>
          <Paragraph>
            Differences between some groups were relatively small: we found just
            a 10 point difference in hookups between those that indicated they
            were religious and those that were not. Other gulfs were wider:
            athletes and those in social organizations were each more than 20
            percent more likely to report having done so.
          </Paragraph>
          <Row>
            <Col lg={8}>
              <YNBarChart variable="have_ever_relationship" />
            </Col>
            <Col lg={4}>
              <Subtitle>Who's cuffed, and when?</Subtitle>
              <Paragraph>
                We then looked at the percent of college students who reported
                having ever been in a comitted relationship - defined however
                respondents judged it - during their time in college.
              </Paragraph>
              <Monospace>
                As before, use the cross tabs to explore the average responses
                by demographics as well as social characteristics, and school
                (where data availability allows).
              </Monospace>
            </Col>
          </Row>
          <Quote>
            "Yalies are obsessed with claiming they don’t have time for a
            relationship and it’s bollocks"
          </Quote>
          <Row>
            <Col lg={4}>
              <Subtitle>Is 'hookup culture' real?</Subtitle>
              <Paragraph>
                Students at nearly every college expressed some version of
                'hookup culture is dominant, and no one wants commitment'. But
                just 25% of all students reported having both 'hooked up' with
                someone and not experiencing a relationship in college, although
                that varied by demograhic group.
              </Paragraph>
              <Monospace>
                Percentages reflect the number of students that said they had
                hooked up with someone, but had not been in a relationship
                during college. Cross tabs show average responses by
                demographics as well as social characteristics and school (where
                data availability allows). Where some data is missing (e.g. when
                a respondent only answered one question), numbers may not add up
                to 100.
              </Monospace>
            </Col>
            <Col lg={8}>
              <YNBarChart variable="only_hu" />
            </Col>
          </Row>
          <Quote>
            "Can’t meet new people, and if you do, it’s somebody within the same
            subset of ~400 students every weekend."
          </Quote>
          <Row>
            <Col lg={4}>
              <Subtitle>Where sparks fly</Subtitle>
              <Paragraph>
                Among those in relationships, where did they meet? Our
                respondents gave a wide variety of answers, which we sorted into
                the general categories of "school", "friends", "online", and
                "summer program". The largest individual group was those that
                met through mutual friends, but more in total met through
                college programming.
              </Paragraph>
              <Monospace>
                Mouse over each bubble to see the description and number of
                students in our sample who met that way. Bubbles are colored by
                group.
              </Monospace>
            </Col>
            <Col lg={8}>
              <Circlepacking variable="how_meet" />
            </Col>
          </Row>
          <Paragraph>
            While 74.3% of students in relationships attended the same school,
            that percentage varied significantly: no students at Wellesley (a
            historically women's college) reported attending the same school as
            their significant other, while all students at Williams (located in
            a remote town with a population of 7,754) indicated they did attend
            the same school as their significant other.
          </Paragraph>
          <Quote>
            "Hookup culture is :( and definitely contributed to my mental state
            last year. But I’ve become more comfortable with myself and being
            alone, and I’ll wait for the right person to come along!"
          </Quote>
          <Row>
            <Col lg={8}>
              <Sankey variable="first_relationship_year" />
            </Col>
            <Col lg={4}>
              <Subtitle>When do relationships begin?</Subtitle>
              <Paragraph>
                Just one quarter of students have a relationship in their first
                year of college. Among those that do not, three-quarters of them
                will eventually enter a relationship. Just shy of 20% of
                students do not enter a relationship at any point in college.
              </Paragraph>
              <Paragraph>
                More optimistically, our data does show that each successive
                year of not being in a relationship increases the probability
                that you will enter one that year. In other words, not having a
                relationship until your third year gives you the best
                probability out of any group that this will be the year for you,
                contrary to what popular opinion might imply. Not all hope is
                lost!
              </Paragraph>
              <Monospace>
                Numbers reflect a hypothtetical distribution of 100 first-years,
                using the individual probabilities for each class first entering
                into a relationship in a given year, conditional on not having
                entered into a relationship at any point prior to that year.
              </Monospace>
            </Col>
          </Row>
          <Quote>
            "[People] make all these hard rules and boundaries for themselves
            ... I wish people would forget about the restrictions of a
            relationship/labels and just enjoy meeting other people and spending
            time together for as long as it feels good."
          </Quote>
          <Row>
            <Col lg={4}>
              <Subtitle>Always cuffed or always searching?</Subtitle>
              <Paragraph>
                We all have that one friend that seems to always somehow be in a
                relationship, and another that hasn't seen anyone in years. But
                do most students really fall into one of these two categories?
                We plotted the distribution of the fraction of time each class
                was in a relationship, among those with at least one
                relationship in college.
              </Paragraph>
              <Paragraph>
                While the binary hypothesis holds for first-years, with each
                year that passes classes successfully 'flatten the curve': more
                respondents drift toward being in a relationship around half the
                time they are in school. Still, noticable spikes at low and high
                values remain – some people really do just need to be in a
                relationship, or alone, all the time.
              </Paragraph>
              <Monospace>
                Percent of time spent in a relationship was inferred by
                multiplying the average length of each relationship with the
                number of relationships a respondent reported being in. Some
                respondents had over 100% of time spent in a relationship, we
                capped those cases at 100%. Respondents without any
                relationships were excluded.
              </Monospace>
            </Col>
            <Col lg={8}>
              <div
                className="grid-container"
                style={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly"
                }}
              >
                <Density
                  demo="year"
                  currentDemo="Class of 2020"
                  variable="percent_time_relationship20"
                  width={360}
                />

                <Density
                  demo="year"
                  currentDemo="Class of 2021"
                  variable="percent_time_relationship21"
                  width={360}
                />

                <Density
                  demo="year"
                  currentDemo="Class of 2022"
                  variable="percent_time_relationship22"
                  width={360}
                />

                <Density
                  demo="year"
                  currentDemo="Class of 2023"
                  variable="percent_time_relationship23"
                  width={360}
                />
              </div>
            </Col>
          </Row>
          <Quote>
            "People are either completely dry and bored or in committed
            relationship, barely anything in the middle that doesn’t then get
            complicated."
          </Quote>
          <Row>
            <Col lg={8}>
              <Scatter variable="times_attempted" />
            </Col>
            <Col lg={4}>
              <Subtitle>Shooting your shot (or getting shot at)</Subtitle>
              <Paragraph>
                It can feel like no matter how many times you 'shoot your shot'
                you come up short. Others might feel as if they are constantly
                being propositioned. We plotted the number of times respondents
                said they propositioned someone against the number of
                relationships they actually entered.
              </Paragraph>
              <Paragraph>
                Not surprisingly, trying more corresponded to entering more
                relationships. But some had more luck than others: while 25% of
                respondents reported being in more relationships than times
                asked, over 40% of respondents (and the largest single
                contingent) reported a lower than 50% success rate. We're
                pouring one out for you all tonight.
              </Paragraph>
              <Paragraph>
                One silver lining, though: even among those with less than 100%
                succcess rates, more attempts at relationships significantly
                predicted higher relationship satisfaction overall (on average,
                10% higher for every 4 attempts)– so even if you fail once or
                twice, try, try, try again!
              </Paragraph>
              <Paragraph>
                Finally, just 10% of respondents had that perfect 1-1 success
                rate, with a majority overall reporting shooting their shot more
                times than they entered a relationship. So if you're turned down
                once or twice, don't worry: you're far from alone.
              </Paragraph>
              <Monospace>
                Numbers slightly adjusted and aggregated to preserve anonymity,
                but proportions kept at the same ratios.
              </Monospace>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default DTF;
