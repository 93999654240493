/** @jsx jsx */

import React, { Component } from "react";
import { jsx, css } from "@emotion/core";

const style = css`
  padding: 12px;
  font-style: italic;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 12px auto;
`;

const Description = ({ children }) => {
  return <div css={style}>{children}</div>;
};

export default Description;
