/** @jsx jsx **/

import { Component } from "react";
import { jsx, css } from "@emotion/core";
import { json, csv, keys, extent, max, nest } from "d3";
import { select, selectAll, mouse } from "d3-selection";
import { timeMinute, timeDay } from "d3-time";
import { format } from "d3-format";
import { path } from "d3-path";
import { scaleOrdinal, scaleLinear, scaleTime, scaleBand } from "d3-scale";
import { schemePaired } from "d3-scale-chromatic";
import { axisBottom, axisLeft, axisTop } from "d3-axis";
import { area, line } from "d3-shape";
import d3Tip from "d3-tip";

const d3 = {
  csv,
  select,
  selectAll,
  mouse,
  path,
  scaleOrdinal,
  scaleLinear,
  scaleTime,
  json,
  keys,
  extent,
  max,
  schemePaired,
  axisBottom,
  axisLeft,
  area,
  line,
  timeMinute,
  timeDay,
  format,
  nest,
  scaleBand,
  axisTop
};

const styles = css`
  display: flex;
`;

export default class LineChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 480,
      height: 360,
      data: null
    };
  }

  componentDidMount() {
    d3.csv(this.props.data)
      .then(data => {
        this.setState({ data: data });

        let finalData = this.formatData();

        this.initialize(finalData);
      })
      .catch(function(err) {
        throw err;
      });
  }

  formatData = () => {
    let cleanedData = this.state.data.map(d => ({
      estimate: +d.clean_estimate,
      lower: +d.clean_lower,
      upper: +d.clean_upper,
      key: +d[this.props.variable]
    }));

    return cleanedData;
  };

  initialize = data => {
    var margin = { top: 10, right: 40, bottom: 60, left: 40 };

    var svg = d3
      .select("#" + this.props.identifier + "-line-chart-container")
      .append("svg")
      .attr("width", this.state.width)
      .attr("height", this.state.height)
      .append("g")
      .attr("id", this.props.identifier + "-line-chart")
      .attr("transform", "translate(" + margin.left + ", " + margin.top + ")");

    this.setState({
      width: this.state.width - margin.right - margin.left,
      height: this.state.height - margin.top - margin.bottom
    });

    this.update(data);
  };

  update = data => {
    var svg = d3.select("#" + this.props.identifier + "-line-chart");

    const xScale = d3
      .scaleLinear()
      .domain(d3.extent(data, d => d.key))
      .range([0, this.state.width]);

    svg.select(".xAxis").remove();

    svg
      .append("g")
      .attr("class", "xAxis")
      .attr("transform", "translate(0," + this.state.height + ")")
      .call(d3.axisBottom(xScale).tickSizeOuter(0));

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, d => d.upper) + 0.1])
      .range([this.state.height, 0]);

    svg.select(".yAxis").remove();

    svg
      .append("g")
      .attr("class", "yAxis")
      .call(d3.axisLeft(yScale));

    const colorScale = d3
      .scaleOrdinal()
      .domain("No", "Yes")
      .range(["#E0A69E", "#ADBBAD"]);

    var tip = d3Tip()
      .attr("class", "d3-tip")
      .html(
        d =>
          this.props.variable +
          ": " +
          d.x +
          ", likelihood " +
          this.props.outcome +
          ": " +
          d.y
      )
      .style("pointer-events", "none");

    svg.call(tip);

    svg
      .append("path")
      .datum(data)
      .attr("fill", "#FFBAC6")
      .attr("stroke", "none")
      .attr(
        "d",
        d3
          .area()
          .x(function(d) {
            return xScale(d.key);
          })
          .y0(function(d) {
            return yScale(d.upper);
          })
          .y1(function(d) {
            return yScale(d.lower);
          })
      )
      .style("opacity", 0.4);

    svg
      .append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "#E0A69E")
      .attr("stroke-width", 1.5)
      .attr(
        "d",
        d3
          .line()
          .x(d => xScale(d.key))
          .y(d => yScale(d.estimate))
      );

    let pointSelect = svg.selectAll(".point").data(data);

    pointSelect
      .enter()
      .append("circle")
      .attr("class", "point")
      .attr("fill", "#E0A69E")
      .attr("stroke", "#E0A69E")
      .attr("r", 3)
      .attr("cx", d => xScale(d.key))
      .attr("cy", d => yScale(d.estimate))
      .on("mouseover", function(d) {
        tip.show({ x: d.key, y: d.estimate }, this);
      })
      .on("mouseout", function(d) {
        tip.hide({ x: d.key, y: d.estimate }, this);
      });
  };

  render() {
    return (
      <div css={styles}>
        <div
          id={this.props.identifier + "-line-chart-container"}
          style={{ margin: "auto" }}
        ></div>
      </div>
    );
  }
}
