/** @jsx jsx */

import React, { Component } from "react";
import { jsx, css } from "@emotion/core";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const footerStyle = css`
  text-align: center;
  padding: 2em 0;

  .link {
    color: #b37c8d !important;
  }

  .link:hover {
    color: #b37c8d !important;
  }
`;

class Footer extends Component {
  render() {
    return (
      <div id="footer" css={footerStyle}>
        <div>
          <a href="http://datamatch.me/" className="link">
            Datamatch is coming February 7!{" "}
          </a>
        </div>
        <div>
          Built with ❤️ by{" "}
          <a href="https://leosaenger.com" target="_blank" className="link">
            Leo Saenger
          </a>{" "}
          and{" "}
          <a href="https://isabellezheng.com" target="_blank" className="link">
            Isabelle Zheng
          </a>
          .
        </div>
      </div>
    );
  }
}

export default Footer;
