/** @jsx jsx */

import React, { Component } from "react";
import { jsx, css } from "@emotion/core";

const style = css`
  padding: 12px;
`;

const Paragraph = ({ children }) => {
  return <div css={style}>{children}</div>;
};

export default Paragraph;
