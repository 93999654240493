/** @jsx jsx **/

import { Component } from "react";
import { jsx, css } from "@emotion/core";
import { json, csvParse, keys, extent, max, nest } from "d3";
import { select, selectAll, event as currentEvent } from "d3-selection";
import { format } from "d3-format";
import { drag } from "d3-drag";
import { scaleOrdinal, scaleLinear, scaleBand } from "d3-scale";
import { axisBottom, axisLeft, axisTop } from "d3-axis";
import { arc, pie } from "d3-shape";
import d3Tip from "d3-tip";

import { firebaseConnect } from "react-redux-firebase";

import { getCSV } from "../utils";

const d3 = {
  csvParse,
  select,
  selectAll,
  drag,
  scaleOrdinal,
  scaleLinear,
  json,
  keys,
  extent,
  max,
  axisBottom,
  axisLeft,
  arc,
  pie,
  format,
  nest,
  scaleBand,
  axisTop
};

const styles = css`
  display: flex;
`;

// TODO: generalize this
const nameToLabel = {
  "In a relationship, longer than 6 months": "In relationship, >6 months",
  "In a relationship, less than 6 months": "In relationship, <6 months",
  "Single and looking for a relationship": "Single, looking",
  "Single and not looking for a relationship": "Single, not looking"
};

class Donut extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 720,
      height: 420,
      data: null
    };
  }

  async componentDidMount() {
    const dataURL = await this.props.firebase
      .storage()
      .ref("clean_survey.csv")
      .getDownloadURL();

    try {
      let data = await getCSV(dataURL);
      data = d3.csvParse(data);
      let finalData = this.formatData(data);

      this.initialize(finalData);
    } catch (e) {
      console.error(e);
    }
  }

  formatData = data => {
    let filteredData = data.filter(
      d => d[this.props.variable] && d[this.props.variable] !== "NA"
    );
    let nestedData = d3
      .nest()
      .key(d => d[this.props.variable])
      .rollup(v => v.length)
      .entries(filteredData);

    return nestedData;
  };

  initialize = data => {
    var margin = { top: 40, right: 40, bottom: 40, left: 0 };

    var svg = d3
      .select("#" + this.props.variable + "-donut-container")
      .append("svg")
      .attr("width", this.state.width)
      .attr("height", this.state.height)
      .append("g")
      .attr("id", this.props.variable + "-donut")
      .attr(
        "transform",
        "translate(" +
          (margin.left + this.state.width / 2) +
          ", " +
          (margin.top + this.state.height / 2) +
          ")"
      );

    this.setState({
      width: this.state.width - margin.right - margin.left,
      height: this.state.height - margin.top - margin.bottom,
      radius: Math.min(this.state.width, this.state.height) / 2 - margin.top
    });

    this.update(data);
  };

  update = data => {
    var svg = d3.select("#" + this.props.variable + "-donut");

    var tip = d3Tip()
      .attr("class", "d3-tip")
      .html(d => d.value)
      .style("pointer-events", "none");
    svg.call(tip);

    var colorScale = d3
      .scaleOrdinal()
      .domain(data.map(d => d.key))
      .range(["#A9AEA5", "#F0C6CD", "#E0A69E", "#B37C8D"]);

    var pie = d3
      .pie()
      .sort(null)
      .value(d => d.value)(data);

    var arc = d3
      .arc()
      .innerRadius(this.state.radius * 0.5) // This is the size of the donut hole
      .outerRadius(this.state.radius * 0.8);

    var outerArc = d3
      .arc()
      .innerRadius(this.state.radius * 0.9)
      .outerRadius(this.state.radius * 0.9);

    svg
      .selectAll("allSlices")
      .data(pie)
      .enter()
      .append("path")
      .attr("d", arc)
      .attr("fill", function(d) {
        return colorScale(d.data.key);
      })
      .attr("stroke", "white")
      .style("stroke-width", "2px")
      .style("opacity", 0.7);

    const radius = this.state.radius;

    // Add the polylines between chart and labels:
    svg
      .selectAll("allPolylines")
      .data(pie)
      .enter()
      .append("polyline")
      .attr("stroke", "black")
      .style("fill", "none")
      .attr("stroke-width", 1)
      .attr("points", function(d) {
        var posA = arc.centroid(d); // line insertion in the slice
        var posB = outerArc.centroid(d); // line break: we use the other arc generator that has been built only for that
        var posC = outerArc.centroid(d); // Label position = almost the same as posB
        var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2; // we need the angle to see if the X position will be at the extreme right or extreme left
        posC[0] = radius * 0.95 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left
        return [posA, posB, posC];
      });

    const total = data.reduce((a, b) => a + b.value, 0);

    // Add the polylines between chart and labels:
    svg
      .selectAll("allLabels")
      .data(pie)
      .enter()
      .append("text")
      .html(function(d) {
        return (
          "<tspan  x='0' dy='1.2em'>" +
          nameToLabel[d.data.key] +
          "</tspan><tspan  x='0' dy='1.2em'> " +
          d3.format(".0%")(d.value / total) +
          "</tspan>"
        );
      })
      .attr("transform", function(d) {
        var pos = outerArc.centroid(d);
        var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        pos[0] = radius * 0.99 * (midangle < Math.PI ? 1 : -1);
        return "translate(" + pos + ")";
      })
      .style("text-anchor", function(d) {
        var midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        return midangle < Math.PI ? "start" : "end";
      });
  };

  render() {
    return (
      <div css={styles}>
        <div
          id={this.props.variable + "-donut-container"}
          style={{ margin: "auto" }}
        ></div>
      </div>
    );
  }
}

export default firebaseConnect()(Donut);
