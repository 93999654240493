/** @jsx jsx */

import React, { Component } from "react";
import { jsx, css } from "@emotion/core";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Paragraph from "../components/Paragraph";
import Title from "../components/Title";
import Subtitle from "../components/Subtitle";
import Monospace from "../components/Monospace";
import Description from "../components/Description";
import Quote from "../components/Quote";

import LineChart from "../viz/line_chart";

import relationship_data from "../assets/data/margins_relationship.csv";
import sex_data from "../assets/data/margins_sex.csv";

const ForeverAloneStyle = css`
  .tbd {
    color: #a9aea5;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    max-width: 800px;
    margin: 2em auto;
    padding: 1em;
    border: 2px solid #a9aea5;
    border-radius: 4px;
    background-color: rgba(222, 227, 218, 0.35);
  }
`;

class ForeverAlone extends Component {
  componentDidMount() {
    this.props.mixpanel.track("Loaded ForeverAlone");
  }
  render() {
    return (
      <div css={ForeverAloneStyle} id="ForeverAlone">
        <Container>
          <Title>Forever Alone</Title>
          <Description>
            How we see and value ourselves, whether in a relationship or not, is
            influenced often by how we think others percieve us, our social and
            academic situations, and a host of other factors. We ask: what
            inspires these negative feelings, and how can we combat them?
          </Description>
          <Paragraph>
            Among nearly every group, many expressed the sentiment that dating
            was harder or more challenging for them. To what degree, though, is
            how we see ourselves a self-fufilling prophecy?
          </Paragraph>
          <Quote>
            "it kinda feels like everyone who’s skinny, rich, and conventionally
            attractive has it waaaay easier when finding dates"
          </Quote>
          <Row>
            <Col lg={5}>
              <Subtitle>
                How does self-perception impact relationship status, and vice
                versa?
              </Subtitle>
              <Paragraph>
                Our percieved self-desirability can both impact our chances of
                being in a relationship, and vice versa. To isolate this
                relationship, we controlled for a respondent's gender, varsity
                athlete status, race, and year in school, then plotted the
                probability an individual is in a relationship against their
                percieved self-desirability (based on a self-assessment from a
                scale of 1 to 8).
              </Paragraph>
              <Paragraph>
                Both plots show a strong relationship between the two: those who
                saw themselves as extremely desirable were most likely to have
                been in a relationship, and vice versa. Interestingly, though,
                the curve flattens at the top: the marginal change past 6/8 is
                relatively small.
              </Paragraph>
              <Monospace>
                Shaded area represents 95% confidence intervals from a logistic
                regression of being in a relationship at some point in college
                on the above control variables and a dummy for each level of
                self-desirability.
              </Monospace>
            </Col>
            <Col lg={7}>
              <Monospace>
                The effect of self desirability on relationship status
              </Monospace>
              <LineChart
                identifier="sd_rel"
                variable="self_desirability"
                outcome=" to have been in a relationship in college"
                data={relationship_data}
              />
              <Monospace>
                The effect of self desirability on having a sexual encounter
              </Monospace>
              <LineChart
                identifier="sd_sex"
                variable="self_desirability"
                outcome=" to have had a sexual encounter in college"
                data={sex_data}
              />
            </Col>
          </Row>
          <Paragraph>
            Datamatch isn’t random (well, it might be – we’ll never tell!) but
            your propensity to go on serendipitous dates does tell us some
            things about your romantic life: those that went on dates were 30%
            more likely to report having been in a relationship after
            controlling for gender, social organization status, class year, and
            race. While this relationship is no longer significant when we
            exclude those who ended up dating their Datamatch dates, it’s still
            positive.
          </Paragraph>
          <Quote>
            Datamatch is the only online dating thing that ever lead to an in
            person meetup for me.
          </Quote>
          <Paragraph>
            Interestingly, going on a Datamatch date is a better predictor of
            having a relationship than using other dating apps (which only
            correspond to a 22% increase in the likelihood of being in a
            relationship). One possibility is that willingness to go on a
            Datamatch date signals a type of openness other dating apps do not —
            we also found that those that tried to match with more dates on
            Datamatch also tried to “shoot their shot” more in real life (at a
            rate of approximately 1 IRL shot for every 10 on Datamatch).
          </Paragraph>
          <Paragraph>
            Either way, the more Datamatch the better (both for your romantic
            life, and for our stats team).
          </Paragraph>
          <div className="tbd">
            More on relationships, self-worth and demographics coming soon! Keep
            an eye on this page...
          </div>
        </Container>
      </div>
    );
  }
}

export default ForeverAlone;
