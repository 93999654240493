/** @jsx jsx **/

import { Component } from "react";
import { jsx, css } from "@emotion/core";
import { json, csv, keys, extent, max, nest } from "d3";
import { select, selectAll, event as currentEvent } from "d3-selection";
import { format } from "d3-format";
import { mean } from "d3-array";
import { scaleOrdinal, scaleLinear, scaleBand } from "d3-scale";
import { axisBottom, axisLeft, axisTop } from "d3-axis";
import { line, symbol, symbolCircle } from "d3-shape";
import d3Tip from "d3-tip";
import { legendColor } from "d3-svg-legend";

import data from "../assets/data/times_attempted.csv";

const d3 = {
  csv,
  select,
  selectAll,
  mean,
  scaleOrdinal,
  scaleLinear,
  json,
  keys,
  extent,
  max,
  axisBottom,
  axisLeft,
  line,
  symbol,
  symbolCircle,
  format,
  nest,
  scaleBand,
  axisTop,
  legendColor
};

const styles = css`
  display: flex;
`;

export default class Scatter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 600,
      height: 540,
      data: null
    };
  }

  componentDidMount() {
    d3.csv(data)
      .then(csvData => {
        this.setState({ data: csvData });

        let finalData = this.formatData();

        this.initialize(finalData);
      })
      .catch(function(err) {
        throw err;
      });
  }

  formatData = () => {
    return this.state.data;
  };

  initialize = data => {
    var margin = { top: 40, right: 40, bottom: 40, left: 40 };

    var svg = d3
      .select("#" + this.props.variable + "-scatter-container")
      .append("svg")
      .attr("width", this.state.width)
      .attr("height", this.state.height)
      .append("g")
      .attr("id", this.props.variable + "-scatter")
      .attr("transform", "translate(" + margin.left + ", " + margin.top + ")");

    this.setState({
      width: this.state.width - margin.right - margin.left,
      height: this.state.height - margin.top - margin.bottom
    });

    this.update(data);
  };

  update = data => {
    var svg = d3.select("#" + this.props.variable + "-scatter");

    var xScale = d3
      .scaleLinear()
      .domain([0, 16])
      .range([0, this.state.width]);

    svg
      .append("g")
      .attr("transform", "translate(0," + this.state.height + ")")
      .call(d3.axisBottom(xScale));

    var yScale = d3
      .scaleLinear()
      .domain([0, 6])
      .range([this.state.height, 0]);
    svg.append("g").call(d3.axisLeft(yScale));

    var colorScale = d3
      .scaleOrdinal()
      .domain(["<50%", "50%-100%", "100%", ">100%"])
      .range(["#A9AEA5", "#F0C6CD", "#E0A69E", "#B37C8D"]);

    svg
      .append("g")
      .attr("class", "legendColor")
      .attr("transform", "translate(" + (this.state.width - 80) + ",20)");

    var legendColor = d3
      .legendColor()
      .shape(
        "path",
        d3
          .symbol()
          .type(d3.symbolCircle)
          .size(120)()
      )
      .shapePadding(10)
      //use cellFilter to hide the "e" cell
      .cellFilter(function(d) {
        return d.label !== "e";
      })
      .title("Success Rate")
      .scale(colorScale);

    svg.select(".legendColor").call(legendColor);

    svg
      .append("g")
      .selectAll("dot")
      .data(data)
      .enter()
      .append("circle")
      .attr("class", function(d) {
        return "dot " + d.success;
      })
      .attr("cx", d => xScale(d.times_attempted_relationship))
      .attr("cy", function(d) {
        return yScale(d.num_relationships);
      })
      .attr("r", 5)
      .attr("stroke", d => colorScale(d.success))
      .style("stroke-width", 1)
      .style("fill", function(d) {
        return colorScale(d.success);
      })
      .style("fill-opacity", 0.5);
  };

  render() {
    return (
      <div css={styles}>
        <div
          id={this.props.variable + "-scatter-container"}
          style={{ margin: "auto" }}
        ></div>
      </div>
    );
  }
}
