/** @jsx jsx */

import React, { Component } from "react";
import { jsx, css } from "@emotion/core";

const style = css`
  padding: 12px;
  font-family: "PT Mono";
  font-size: 0.8em;
`;

const Monospace = ({ children }) => {
  return <div css={style}>{children}</div>;
};

export default Monospace;
