/** @jsx jsx */

import React, { Component } from "react";
import { jsx, css } from "@emotion/core";

const style = css`
  padding: 12px;
  font-size: 24px;
  font-weight: 500;
`;

const Subtitle = ({ children }) => {
  return <div css={style}>{children}</div>;
};

export default Subtitle;
