/** @jsx jsx */

import React, { Component } from "react";
import { jsx, css } from "@emotion/core";

const style = css`
  padding: 8px;
  text-align: center;
  font-size: 36px;
  font-weight: 700;
`;

const Title = ({ children }) => {
  return <div css={style}>{children}</div>;
};

export default Title;
