/** @jsx jsx **/

import { Component } from "react";
import { jsx, css } from "@emotion/core";
import { json, csv, keys, extent, max, nest, csvParse } from "d3";
import { select, selectAll, event as currentEvent } from "d3-selection";
import { format } from "d3-format";
import { drag } from "d3-drag";
import { scaleOrdinal, scaleLinear, scaleBand } from "d3-scale";
import {
  forceSimulation,
  forceX,
  forceY,
  forceCenter,
  forceManyBody,
  forceCollide
} from "d3-force";
import { axisBottom, axisLeft, axisTop } from "d3-axis";
import { line, symbol, symbolCircle } from "d3-shape";
import d3Tip from "d3-tip";
import { legendColor } from "d3-svg-legend";

import { firebaseConnect } from "react-redux-firebase";
import { getCSV } from "../utils";

const d3 = {
  csvParse,
  select,
  selectAll,
  drag,
  scaleOrdinal,
  scaleLinear,
  json,
  keys,
  extent,
  max,
  forceSimulation,
  forceX,
  forceY,
  forceCenter,
  forceManyBody,
  forceCollide,
  axisBottom,
  axisLeft,
  symbol,
  symbolCircle,
  line,
  format,
  nest,
  scaleBand,
  axisTop,
  legendColor
};

const styles = css`
  display: flex;
`;

const groups = {
  "School-related": [
    "Extracurricular Organization",
    "Class",
    "High School",
    "College Orientation",
    "Housing",
    "Study Abroad"
  ],
  Social: ["Party/social Event", "Mutual Friends", "Social Organization"],
  Online: ["Datamatch", "Dating App", "Facebook Group"],
  Summer: ["Internship/job", "Summer Camp/program"]
};

const titleCase = str => {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

class Circlepacking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 640,
      height: 360,
      data: null
    };
  }

  async componentDidMount() {
    const dataURL = await this.props.firebase
      .storage()
      .ref("clean_survey.csv")
      .getDownloadURL();

    try {
      let data = await getCSV(dataURL);
      data = d3.csvParse(data);
      let finalData = this.formatData(data);

      this.initialize(finalData);
    } catch (e) {
      console.error(e);
    }
  }

  formatData = data => {
    let filteredData = data.filter(
      d => d[this.props.variable] && d[this.props.variable] !== "NA"
    );

    let nestedData = d3
      .nest()
      .key(d => d[this.props.variable])
      .rollup(v => v.length)
      .entries(filteredData);

    nestedData = nestedData.map(obj => {
      let selectedGroup;
      for (var group in groups) {
        if (groups[group].includes(titleCase(obj.key))) {
          selectedGroup = group;
        }
      }

      return {
        key: titleCase(obj.key),
        value: obj.value,
        group: selectedGroup
      };
    });
    return nestedData;
  };

  initialize = data => {
    var margin = { top: 40, right: 140, bottom: 60, left: 40 };

    var svg = d3
      .select("#" + this.props.variable + "-circlepacking-container")
      .append("svg")
      .attr("width", this.state.width)
      .attr("height", this.state.height)
      .append("g")
      .attr("id", this.props.variable + "-circlepacking")
      .attr("transform", "translate(" + margin.left + ", " + margin.top + ")");

    this.setState({
      width: this.state.width - margin.right - margin.left,
      height: this.state.height - margin.top - margin.bottom
    });

    this.update(data);
  };

  update = data => {
    var svg = d3.select("#" + this.props.variable + "-circlepacking");

    var xScale = d3
      .scaleOrdinal()
      .domain(Object.keys(groups))
      .range([50, 150, 250, 350]);

    var rScale = d3
      .scaleLinear()
      .domain([0, d3.max(data, d => d.value)])
      .range([5, 60]);

    var colorScale = d3
      .scaleOrdinal()
      .domain(Object.keys(groups))
      .range(["#A9AEA5", "#F0C6CD", "#E0A69E", "#B37C8D"]);

    svg
      .append("g")
      .attr("class", "legendColor")
      .attr("transform", "translate(" + (this.state.width + 10) + ",20)");

    var legendColor = d3
      .legendColor()
      .shape(
        "path",
        d3
          .symbol()
          .type(d3.symbolCircle)
          .size(120)()
      )
      .shapePadding(10)
      //use cellFilter to hide the "e" cell
      .cellFilter(function(d) {
        return d.label !== "e";
      })
      .title("How They Met")
      .scale(colorScale);

    svg.select(".legendColor").call(legendColor);

    var tip = d3Tip()
      .attr("class", "d3-tip")
      .offset([-10, 0])
      .html(
        d =>
          "<b style='color:" +
          colorScale(d.group) +
          "'>" +
          d.key +
          "</b>: " +
          d.value
      )
      .style("pointer-events", "none");

    svg.call(tip);

    // Features of the forces applied to the nodes:
    var simulation = d3
      .forceSimulation()
      .force(
        "x",
        d3
          .forceX()
          .strength(0.5)
          .x(function(d) {
            return xScale(d.group);
          })
      )
      .force(
        "y",
        d3
          .forceY()
          .strength(0.1)
          .y(this.state.height / 2)
      )
      .force(
        "center",
        d3
          .forceCenter()
          .x(this.state.width / 2)
          .y(this.state.height / 2)
      ) // Attraction to the center of the svg area
      .force("charge", d3.forceManyBody().strength(1)) // Nodes are attracted one each other of value is > 0
      .force(
        "collide",
        d3
          .forceCollide()
          .strength(0.1)
          .radius(32)
          .iterations(1)
      ); // Force that avoids circle overlapping

    const dragstarted = d => {
      if (!currentEvent.active) simulation.alphaTarget(0.03).restart();
      d.fx = d.x;
      d.fy = d.y;
    };
    const dragged = d => {
      d.fx = currentEvent.x;
      d.fy = currentEvent.y;
    };
    const dragended = d => {
      if (!currentEvent.active) simulation.alphaTarget(0.03);
      d.fx = null;
      d.fy = null;
    };

    var node = svg
      .append("g")
      .selectAll("circle")
      .data(data)
      .enter()
      .append("circle")
      .attr("r", d => rScale(d.value))
      .attr("cx", this.state.width / 2)
      .attr("cy", this.state.height / 2)
      .style("fill", d => colorScale(d.group))
      .style("fill-opacity", 0.8)
      .attr("stroke", d => colorScale(d.group))
      .style("stroke-width", 3)
      .call(
        d3
          .drag() // call specific function when circle is dragged
          .on("start", dragstarted)
          .on("drag", dragged)
          .on("end", dragended)
      );

    node
      .on("mouseover", function(d) {
        tip.show(d, this);
      })
      .on("mouseout", function(d) {
        tip.hide(d, this);
      });

    // Apply these forces to the nodes and update their positions.
    // Once the force algorithm is happy with positions ('alpha' value is low enough), simulations will stop.
    simulation.nodes(data).on("tick", function(d) {
      node
        .attr("cx", function(d) {
          return d.x;
        })
        .attr("cy", function(d) {
          return d.y;
        });
    });
  };

  render() {
    return (
      <div css={styles}>
        <div
          id={this.props.variable + "-circlepacking-container"}
          style={{ margin: "auto" }}
        ></div>
      </div>
    );
  }
}

export default firebaseConnect()(Circlepacking);
