/** @jsx jsx **/

import { Component } from "react";
import { jsx, css } from "@emotion/core";
import { json, csvParse, keys, extent, max, nest } from "d3";
import {
  select,
  selectAll,
  mouse,
  pointer,
  event as currentEvent
} from "d3-selection";
import { timeMinute, timeDay } from "d3-time";
import { format } from "d3-format";
import { path } from "d3-path";
import { scaleOrdinal, scaleLinear, scaleTime, scaleBand } from "d3-scale";
import { axisBottom, axisLeft, axisTop } from "d3-axis";
import { stack } from "d3-shape";
import d3Tip from "d3-tip";

import DemographicSelect from "../components/DemographicSelect";

import { firebaseConnect } from "react-redux-firebase";
import { getCSV } from "../utils";

const d3 = {
  csvParse,
  select,
  selectAll,
  mouse,
  currentEvent,
  path,
  scaleOrdinal,
  scaleLinear,
  scaleTime,
  json,
  keys,
  extent,
  max,
  axisBottom,
  axisLeft,
  stack,
  timeMinute,
  timeDay,
  format,
  nest,
  scaleBand,
  axisTop
};

const styles = css`
  display: flex;
  .xAxis {
    text-transform: capitalize;
  }
`;

const demoToKey = {
  "Class Year": "year",
  Major: "major",
  Athlete: "athlete",
  "Social Org": "social_org",
  Race: "race",
  Gender: "gender",
  Religion: "religious",
  School: "school"
};

const acceptedSchools = [
  "Boston College",
  "Brown University",
  "Carleton College",
  "Dartmouth College",
  "Harvard College",
  "Massachusetts Institute of Technology",
  "The Claremont Colleges",
  "The University of Chicago",
  "Tufts University",
  "University of Pennsylvania",
  "University of Washington",
  "University of Wisconsin-Madison",
  "Washington University in St. Louis",
  "Wesleyan University",
  "Yale University"
];

const schoolLabels = {
  All: "All",
  "Boston College": "BC",
  "Brown University": "Brown",
  "Carleton College": "Carleton",
  "Dartmouth College": "Dartmouth",
  "Harvard College": "Harvard",
  "Massachusetts Institute of Technology": "MIT",
  "The Claremont Colleges": "Claremonts",
  "The University of Chicago": "UChicago",
  "Tufts University": "Tufts",
  "University of Pennsylvania": "UPenn",
  "University of Washington": "UW",
  "University of Wisconsin-Madison": "UW Madison",
  "Washington University in St. Louis": "WashU",
  "Wesleyan University": "Wesleyan",
  "Yale University": "Yale"
};

class YNBarChart extends Component {
  constructor(props) {
    super(props);

    this.state = { demo: "Class Year", width: 480, height: 400, data: null };
  }

  async componentDidMount() {
    const dataURL = await this.props.firebase
      .storage()
      .ref("clean_survey.csv")
      .getDownloadURL();

    try {
      let data = await getCSV(dataURL);
      data = d3.csvParse(data);

      this.setState({ data });
      let { finalData, stackedData } = this.formatData();

      this.initialize(finalData, stackedData);
    } catch (e) {
      console.error(e);
    }
  }

  handleDemoSelect = demo => {
    this.setState({ demo: demo }, () => {
      let { finalData, stackedData } = this.formatData();
      this.update(finalData, stackedData);
    });
  };

  formatData = () => {
    let filteredData = this.state.data.filter(
      d =>
        d[this.props.variable] &&
        d[demoToKey[this.state.demo]] &&
        d[demoToKey[this.state.demo]] !== "Other" &&
        d[demoToKey[this.state.demo]] !== "NA" &&
        d[demoToKey[this.state.demo]] !== "Prefer to self-describe"
    );

    if (this.state.demo === "School") {
      filteredData = filteredData.filter(d =>
        acceptedSchools.includes(d[demoToKey[this.state.demo]])
      );
    }
    if (this.state.demo === "Major") {
      filteredData = filteredData.filter(
        d =>
          !["architecture", "education", "music", "nursing"].includes(
            d[demoToKey[this.state.demo]]
          )
      );
    }

    let nestedData = d3
      .nest()
      .key(d => d[demoToKey[this.state.demo]])
      .key(d => d[this.props.variable])
      .rollup(v => v.length)
      .entries(filteredData);
    const all_data = d3
      .nest()
      .key(d => d[this.props.variable])
      .rollup(v => v.length)
      .entries(filteredData);
    nestedData.push({ key: "All", values: all_data });

    let finalData = nestedData.map(d => {
      const total = d.values.reduce((acc, val) => acc + val.value, 0);
      const vals = d.values.map(d2 => ({
        [d2.key]: d2.value / total
      }));
      return {
        key: d.key,
        ...vals[0],
        ...vals[1]
      };
    });

    // sort for major and school
    if (this.state.demo === "School" || this.state.demo === "Major") {
      finalData.sort((a, b) => {
        if (a.key === "All") {
          return -1000;
        } else if (b.key === "All") {
          return 1000;
        } else {
          return b["Yes"] - a["Yes"];
        }
      });
    }

    const stackedData = d3.stack().keys(["Yes", "No"])(finalData);

    return { finalData: finalData, stackedData: stackedData };
  };

  initialize = (data, stackedData) => {
    var margin = { top: 40, right: 40, bottom: 100, left: 40 };

    var svg = d3
      .select("#" + this.props.variable + "-yn-barchart-container")
      .append("svg")
      .attr("width", this.state.width)
      .attr("height", this.state.height)
      .append("g")
      .attr("id", this.props.variable + "-bar")
      .attr("transform", "translate(" + margin.left + ", " + margin.top + ")");

    this.setState({
      width: this.state.width - margin.right - margin.left,
      height: this.state.height - margin.top - margin.bottom
    });

    this.update(data, stackedData);
  };

  update = (data, stackedData) => {
    var svg = d3.select("#" + this.props.variable + "-bar");
    const groups = data.map(d => d.key);
    if (!["School", "Major"].includes(this.state.demo)) {
      groups.sort();
    }

    const xScale = d3
      .scaleBand()
      .domain(groups)
      .range([0, this.state.width])
      .padding([0.3]);

    svg.select(".xAxis").remove();

    svg
      .append("g")
      .attr("class", "xAxis")
      .attr("transform", "translate(0," + (this.state.height + 10) + ")")
      .call(
        d3
          .axisBottom(xScale)
          .tickSizeOuter(0)
          .tickSize(0)
      );

    if (["Major", "Race"].includes(this.state.demo)) {
      svg
        .select(".xAxis")
        .selectAll("text")
        .attr("y", 0)
        .attr("x", 9)
        .attr("dy", ".35em")
        .attr("transform", "rotate(90)")
        .style("text-anchor", "start");
    }

    if (this.state.demo === "School") {
      svg
        .select(".xAxis")
        .selectAll("text")
        .attr("y", 0)
        .attr("x", 9)
        .attr("dy", ".35em")
        .attr("transform", "rotate(90)")
        .style("text-anchor", "start")
        .text(d => schoolLabels[d]);
    }

    svg
      .select(".xAxis")
      .select(".domain")
      .attr("stroke-width", 0);

    const yScale = d3
      .scaleLinear()
      .domain([0, 1])
      .range([this.state.height, 0]);

    svg.select(".yAxis").remove();

    svg
      .append("g")
      .attr("class", "yAxis")
      .call(
        d3
          .axisLeft(yScale)
          .tickFormat(d3.format(".0%"))
          .tickSize(0)
      );

    const colorScale = d3
      .scaleOrdinal()
      .domain(["Yes", "No"])
      .range(["#ADBBAD", "#E0A69E"]);

    var tip = d3Tip()
      .attr("class", "d3-tip")
      .offset([-10, 0])
      .html(
        d =>
          "<b style='color:" +
          colorScale(d.key) +
          "'>" +
          d.key +
          "</b>: " +
          d.value +
          "%"
      )
      .style("pointer-events", "none");

    svg.call(tip);

    var answerSelect = svg.selectAll(".answer").data(stackedData);

    answerSelect.exit().remove();

    var answer = answerSelect
      .enter()
      .append("g")
      .merge(answerSelect)
      .attr("class", "answer")
      .attr("fill", d => colorScale(d.key));

    var rectSelect = answer.selectAll("rect").data(d => {
      return d;
    });

    rectSelect.exit().remove();

    var rect = rectSelect
      .enter()
      .append("rect")
      .merge(rectSelect)
      .attr("x", d => xScale(d.data.key))
      .attr("y", d => yScale(d[1]))
      .attr("height", d => yScale(d[0]) - yScale(d[1]))
      .attr("width", xScale.bandwidth())
      .attr("pointer-events", "all")
      .style("cursor", "pointer")
      .on("mouseover", function(d) {
        var key = d3.select(this.parentNode).datum().key;
        tip.show(
          { key: key, value: d3.format(".1f")(d.data[key] * 100) },
          this
        );
      })
      .on("mouseout", function(d) {
        var key = d3.select(this.parentNode).datum().key;
        tip.hide(
          { key: key, value: d3.format(".1f")(d.data[key] * 100) },
          this
        );
      });
  };

  render = () => {
    return (
      <div css={styles}>
        <div
          id={this.props.variable + "-yn-barchart-container"}
          style={{ margin: "auto" }}
        ></div>
        <DemographicSelect
          handleChange={this.handleDemoSelect}
          currentDemo={this.state.demo}
        />
      </div>
    );
  };
}

export default firebaseConnect()(YNBarChart);
