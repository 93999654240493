/** @jsx jsx */

import React, { Component } from "react";
import { jsx, css } from "@emotion/core";

const style = css`
  padding: 0 1em;
  font-style: italic;
  font-size: 20px;
  text-align: center;
  max-width: 800px;
  font-weight: 600;
  margin: 2em auto;
  color: #e0a69e;
  border-left: 10px solid #e0a69e;
`;

const Quote = ({ children }) => {
  return <div css={style}>{children}</div>;
};

export default Quote;
